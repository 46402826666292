<template>
  <div class="container">
    <div v-if="hideDiv">
      <PageLoader></PageLoader>
    </div>
    <div v-else>
      <div class="modal fade" tabindex="-1" id="invest-slot">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header title_modal">
              <h5 class="modal-title">Investments Slots</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body ">
              <p class="slotsnum">
                Available Slots: {{ investment.computedremaining }} slots
              </p>
              <div class="select-slots">
                <div class="slots">
                  <div
                    class="slot"
                    :class="[slot === 1 ? 'activeSlot' : '']"
                    @click="slot = 1"
                  >
                    1 Slot
                  </div>
                  <div
                    class="slot"
                    :class="[slot === 3 ? 'activeSlot' : '']"
                    @click="slot = 3"
                  >
                    3 Slots
                  </div>
                  <div
                    class="slot"
                    :class="[slot === 5 ? 'activeSlot' : '']"
                    @click="slot = 5"
                  >
                    5 Slots
                  </div>
                  <div
                    class="slot"
                    :class="[slot === 10 ? 'activeSlot' : '']"
                    @click="slot = 10"
                  >
                    10 Slots
                  </div>

                  <div class="custom-slot"></div>
                </div>
              </div>
              <h6 style="margin-top: 15px; font-family: Lato; font-size: 14px;">
                OR
              </h6>
              <div class="custom-slots pl-4 pr-4">
                <div class="mb-3 mt-3 input-group">
                  <span class="input-group-text">No of slot</span>

                  <input
                    type="number"
                    class="form-control pt-4 pb-4"
                    id="custom-slot"
                    min="0"
                    placeholder="Number of slot"
                    v-model="slot"
                  />
                </div>
                <p class="slotsnum" v-if="slot">
                  Total Amounts: ₦{{
                    Number(investment.costperslot * slot).toLocaleString()
                  }}
                </p>
              </div>
            </div>
            <div class="py-4">
              <button
                data-dismiss="modal"
                aria-label="Close"
                class="mr-3 secondary"
              >
                Cancel
              </button>
              <button class="main" @click="investNow" :disabled="loading">
                Invest
                <i
                  v-if="loading"
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="pt-3 pb-3 text-left explore-text"
        style="color: inherit; text-decoration: none;"
      >
        <RouterLink to="/dashboard/invest">Explore assets</RouterLink>
      </div>

      <div class="asset-info">
        <div class="asset-info__header">
          <div class=" asset-info__header--name">
            <div>
              <img
                src="../assets/images/invest_card_icon.svg"
                alt="card icon"
              />
            </div>
            <div>
              <p class="asset-info__title">{{ investment.investmentname }}</p>
              <p class="asset-info__price">
                ₦{{ Number(investment.costperslot).toLocaleString() }}
                <span>/slot</span> <span class="avl">({{ investment.computedremaining }} avl.)</span>
              </p>
              <div class="asset-info__badge" :class="[investment.status != 'Ongoing' ? 'soldout' : '']">
                {{ investment.status }}
              </div>
            </div>
          </div>
          <button data-toggle="modal" data-target="#invest-slot">
            Invest Now
          </button>
        </div>

        <div class="about-header">
          <h3>About this Asset</h3>
          <p>
            {{ investment.description }}
          </p>
        </div>

        <div class="asset-info__details">
          <h5>Details</h5>
          <div class="details-list">
            <div class="asset-info__details--detail">
              <p>Amount per slot</p>
              <span
                >₦{{ Number(investment.costperslot).toLocaleString() }}</span
              >
            </div>
            <div>
              <p>Holding period</p>
              <span>{{ investment.holding_period }}</span>
            </div>
            <div>
              <p>Investment type</p>
              <span>{{ investment.type }}</span>
            </div>
            <div>
              <p>Est. ROI</p>
              <span>{{ investment.roi }}%</span>
            </div>
            <div>
              <p>Available Slots</p>
              <span>{{ investment.computedremaining }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import PageLoader from "../components/PageLoader.vue";
export default {
  components: {
    PageLoader
  },
  data() {
    return {
      loading: false,
      hideDiv: false,
      slot: "",
      investId: "",
      investment: ""
    };
  },
  mounted() {
    this.investId = this.$route.params.id;
    this.investdetails();
  },
  methods: {
    async investNow() {
      this.loading = true;
      try {
        if (this.slot === "") {
          this.loading = false;
          return this.$store.commit("setApiFailed", "Add or select a slot");
        }
        await Api.postRequest(`doinvest`, {
          quantity: this.slot,
          investmentid: this.investment.id
        })
          .then(res => {
            // console.log("Investment done", res);
            this.loading = false;
            if (res.data.success) {
              $("#invest-slot")
                .removeClass("fade")
                .modal("hide");
              this.investdetails();
              return this.$store.commit("setApiSuccess", "Success");
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Closed");
      }
    },
    investdetails() {
      this.hideDiv = true;
      Api.getRequest(`getcooperativeinvestmentbyid/${this.investId}`)
        .then(res => {
          this.hideDiv = false;
          if (res.data.success) {
            // console.log("Invest details", res);
            this.investment = res.data.inv;
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
          this.hideDiv = false;
          this.$store.commit("setApiFailed", "Error occured");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

.explore-text {
  a {
    color: $primary;
    font-family: Lato;
    font-size: 20px;
    text-decoration: none;
  }
}

.asset-info * {
  margin: 0;
  padding: 0;
}

button {
  background-color: transparent;
  border: none;
  font-family: Lato;
}

button.secondary {
  border: 1px solid #ddd;
  background-color: transparent;
  color: #000a2f;
  padding: 14px 2rem;
  border-radius: 4px;
  transition: all 350ms ease-in-out;

  &:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
}

button.main {
  background-color: $primary;
  color: #fff;
  padding: 14px 2rem;
  border-radius: 4px;
  transition: all 350ms ease-in-out;

  &:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
}

.avl {
  color: #57595a !important;
  font-family: Lato;
  font-size: 11px !important;
}


.soldout {
  color: #EB0000 !important;
  background: #f7e7e7 !important;
}

.asset-info {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: left;
  min-height: 100vh;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #d9d9d9;
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;
    font-family: Lato;

    &--name {
      display: flex;
      gap: 12px;
      align-items: baseline;
      font-family: Lato;
    }

    img {
      width: 100%;
    }

    button {
      background-color: $primary;
      color: #fff;
      padding: 14px 2rem;
      border-radius: 4px;
      transition: all 350ms ease-in-out;

      &:hover {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__badge {
    font-size: 10px;
    color: #2bc300;
    background-color: #e7faf1;
    padding: 4px 8px;
    border-radius: 10px;
    display: inline-block;
    font-family: Lato;
  }

  &__title {
    font-size: clamp(18px, 3vw, 24px);
    font-family: Lato;
  }

  &__price {
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 700;
    font-family: Lato;

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__type {
    font-size: 10px;
    color: #57595a;
    background: #f9f9f9;
    padding: 2px 4px;
    border-radius: 8px;
    display: inline-block;
    font-family: Lato;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    p {
      font-weight: 600;
      font-size: 13px;
      font-family: Lato;
    }

    span {
      font-size: 8px;
      font-family: Lato;
    }

    > * {
      flex: 1;
      text-align: left;
    }

    & > * + * {
      border-left: 1px solid #ebebeb;
    }
  }

  &__details {
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    padding: 1rem 1.5rem;

    h5 {
      font-size: 24px;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      font-family: Lato;
    }

    .details-list {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      > * {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        @media screen and (min-width: 768px) {
          flex-direction: column;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 1rem;
          gap: 1rem;
        }

        span {
          font-weight: 600;
          font-family: Lato;
        }
      }

      > * + * {
        border-top: 1px solid #d9d9d9;

        @media screen and (min-width: 768px) {
          border-top: none;
          border-left: 1px solid #d9d9d9;
        }
      }
    }
  }
}

.about-header {
  h3 {
    font-size: 28px;
    line-height: 38px;
    font-family: Lato;
  }

  p {
    font-family: Lato;
    font-size: 20px;
  }
}
.slotsnum {
  font-family: Lato;
  font-size: 16px;
}
.slots {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
}

.slot {
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  padding: 8px 16px;
  cursor: pointer;
}

.activeSlot {
  border: 1px solid $primary;
  color: $primary;
}

.form-control {
  outline: 0;
  box-shadow: none;
}

.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Side-drawer

@media only screen and (max-width: 599px) {
  .asset-info__header {
    display: block;

    button {
      margin-top: 30px;
    }
  }

  .about-header {
    h3 {
      font-size: 22px;
      line-height: 38px;
    }

    p {
      font-size: 16px;
    }
  }
  .asset-info__details {
    h5 {
      font-size: 18px;
      padding-bottom: 1rem;
    }
  }
}
</style>
